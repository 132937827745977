module.exports = [{
      plugin: require('/builds/griffobeid/new-hue-painting/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-33651775-1","head":true},
    },{
      plugin: require('/builds/griffobeid/new-hue-painting/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/builds/griffobeid/new-hue-painting/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
